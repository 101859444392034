@font-face {
  font-family: "CustomFontRegular";
  src: url("fonts/domaine-display-regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CustomFontRegularItalic";
  src: url("fonts/domaine-display-regular-italic.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "axiforma";
  src: url("fonts/Axiforma-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "axiforma-light";
  src: url("fonts/Axiforma-Light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "axiforma-thin";
  src: url("fonts/Axiforma-Thin.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "CustomFontRegular", sans-serif;
  background-image: url("./assets/background.png");
  background-size: "cover";
  background-repeat: "no-repeat";
  background-attachment: "fixed";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -ms-overflow-style: none; /* IE and Edge */
  -webkit-scrollbar: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.orange {
  background-color: #ff8360 !important;
}

.blue {
  background-color: #40445c !important;
}

@media only screen and (pointer: coarse) and (min-width: 320px) and (max-width: 812px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}
